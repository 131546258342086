.translucent-banner {
  background-color: rgba(255, 140, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 0.875em;
}

.translucent-banner .icon {
  margin-right: 5px;
  font-size: 1.2em;
}
